import React from 'react'

const style = {
  stroke: 'none',
  fillRule: 'nonzero',
  fill: '#f03c2e',
  fillOpacity: 1,
}
const gitLogo = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 92 92">
      <defs>
        <clipPath id="a">
          <path d="M0 .113h91.887V92H0Zm0 0" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          style={style}
          d="M90.156 41.965 50.036 1.848a5.918 5.918 0 0 0-8.372 0l-8.328 8.332 10.566 10.566a7.03 7.03 0 0 1 7.23 1.684 7.034 7.034 0 0 1 1.669 7.277l10.187 10.184a7.028 7.028 0 0 1 7.278 1.672 7.04 7.04 0 0 1 0 9.957 7.05 7.05 0 0 1-9.965 0 7.044 7.044 0 0 1-1.528-7.66l-9.5-9.497V59.36a7.04 7.04 0 0 1 1.86 11.29 7.04 7.04 0 0 1-9.957 0 7.04 7.04 0 0 1 0-9.958 7.06 7.06 0 0 1 2.304-1.539V33.926a7.049 7.049 0 0 1-3.82-9.234L29.242 14.272 1.73 41.777a5.925 5.925 0 0 0 0 8.371L41.852 90.27a5.925 5.925 0 0 0 8.37 0l39.934-39.934a5.925 5.925 0 0 0 0-8.371"
        />
      </g>
    </svg>
  )
}
export default gitLogo
