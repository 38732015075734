import React from 'react'
const javascriptLogo = () => {
  return (
    <svg
      fill="none"
      height="2500"
      width="2183"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 124 141.53199999999998"
    >
      <path
        d="M10.383 126.894L0 0l124 .255-10.979 126.639-50.553 14.638z"
        fill="#e9ca32"
      />
      <path
        d="M62.468 129.277V12.085l51.064.17-9.106 104.851z"
        fill="#ffde25"
      />
      <g fill="#fff">
        <path d="M57 26H43.5v78L33 102V91.5l-12.5-2V113l36.5 9.5zM67.127 26H104.5L102 40.95H81.394v24.533H102L99.5 115l-32.373 7.5V107L89 99.5 90.263 79l-23.136 3.35z" />
      </g>
    </svg>
  );
};

export default javascriptLogo;
