/* eslint-disable react/prop-types */
import React from 'react'

const IconPath = (props) => {
  const { fill = '#fff' } = props
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.43681 8.1142H3.60837C3.43846 8.1142 3.30078 8.25194 3.30078 8.42179V20.7209C3.30078 20.8908 3.43846 21.0285 3.60837 21.0285H7.43681C7.60672 21.0285 7.74439 20.8908 7.74439 20.7209V8.42179C7.74439 8.25194 7.60672 8.1142 7.43681 8.1142Z"
        fill={fill}
      />
      <path
        d="M5.52378 2C4.13094 2 2.9978 3.13191 2.9978 4.52321C2.9978 5.91513 4.13094 7.04747 5.52378 7.04747C6.91551 7.04747 8.04773 5.91507 8.04773 4.52321C8.04779 3.13191 6.91551 2 5.52378 2Z"
        fill={fill}
      />
      <path
        d="M17.1748 7.80847C15.6372 7.80847 14.5005 8.46948 13.8111 9.22055V8.42174C13.8111 8.25189 13.6734 8.11415 13.5035 8.11415H9.83713C9.66722 8.11415 9.52954 8.25189 9.52954 8.42174V20.7209C9.52954 20.8908 9.66722 21.0285 9.83713 21.0285H13.6572C13.8271 21.0285 13.9648 20.8908 13.9648 20.7209V14.6357C13.9648 12.5851 14.5218 11.7862 15.9512 11.7862C17.508 11.7862 17.6317 13.0669 17.6317 14.7412V20.7209C17.6317 20.8908 17.7694 21.0285 17.9393 21.0285H21.7608C21.9307 21.0285 22.0683 20.8908 22.0683 20.7209V13.9746C22.0683 10.9255 21.4869 7.80847 17.1748 7.80847Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconPath
